// 
// 
// ion-rangeslider.scss
//
//

@import "CSS:../plugins/ion.rangeSlider";

/* Ion.RangeSlider, Flat UI Skin
// css version 2.0.3
// © Denis Ineshin, 2014    https://github.com/IonDen
// ===================================================================================================================*/

/* =====================================================================================================================
// Skin details */

.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge,
.irs-slider {
    background: url(../img/sprite-skin-flat.png) repeat-x;
}

.irs {
    height: 40px;
}

.irs-with-grid {
    height: 60px;
}

.irs-line {
    height: 12px;
    top: 25px;
}

.irs-line-left {
    height: 12px;
    background-position: 0 -30px;
}

.irs-line-mid {
    height: 12px;
    background-position: 0 0;
}

.irs-line-right {
    height: 12px;
    background-position: 100% -30px;
}

.irs-bar {
    height: 6px;
    top: 27px;
    background-color: theme-color('primary');
    border-radius: 200px;
}

.irs-bar-edge {
    top: 25px;
    height: 12px;
    width: 9px;
    background-position: 0 -90px;
}

.irs-shadow {
    height: 3px;
    top: 34px;
    background: #000;
    opacity: 0.25;
}

.lt-ie9 .irs-shadow {
    filter: alpha(opacity=25);
}

.irs-slider {
    width: 16px;
    height: 18px;
    top: 22px;
    background-position: 0 -120px;
}

.irs-slider.state_hover,
.irs-slider:hover {
    background-position: 0 -150px;
}

.irs-min,
.irs-max {
    opacity: 0;
}

.irs-from,
.irs-to,
.irs-single {
    color: #fff;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background: theme-color('dark');
    border-radius: 4px;
}

.irs-from:after,
.irs-to:after,
.irs-single:after {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: theme-color('dark');
}

.irs-grid {
    bottom: -8px;
}

.irs-grid-pol {
    background: $text-muted;
}

.irs-grid-text {
    margin-top: .5rem;
    color: $text-muted;
    font-size: $small-font-size;
    font-weight: $font-weight-bold;
    bottom: -8px;
}

.irs-disabled {}

.irs-slider.single {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    box-shadow: $box-shadow-sm;
    border: $border-width solid $border-color;
    background: $white;
    cursor: pointer;
    transition: background-color $transition-time ease;
    &.state_hover {
        background-color: theme-color('primary');
    }
}
