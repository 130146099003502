//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.



$font-family-sans-serif:      'Poppins',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;

$headings-font-family:        'Poppins';
